import 'date-fns';
import React, { Suspense, lazy, useState, useEffect, } from 'react';
import { BrowserRouter, Switch, Route, Redirect, } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, } from '@material-ui/pickers';
import LinearProgress from '@material-ui/core/LinearProgress';
import { request } from './helpers/jsAPI';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setIsLoggedIn } from './redux/actions/auth';

import theme from './theme';
import AppRouter from './router/AppRouter';
const SignUpScreen = lazy(() => import('./screens/SignUp'));
const SignInScreen = lazy(() => import('./screens/SignIn'));
const NotFoundScreen = lazy(() => import('./screens/NotFound'));

const App = (props) => {
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const checkSession = async () => {
			const responseJSON = await request("/api/user/check");
			props.actions.setIsLoggedIn(responseJSON.success);
			setIsLoading(false);
		}

		checkSession();
	}, [props.actions]);

	return (
		<>
			{isLoading === true ? (
				<LinearProgress />
			) : (
					<ThemeProvider theme={theme}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<BrowserRouter>
								<Suspense fallback={<LinearProgress />}>
									<Switch>
										<Route
											exact
											path={'/signup/:slug'}
											component={SignUpScreen}
										/>
										<Route
											exact
											path={'/notfound'}
											component={NotFoundScreen}
										/>
										{!props.isLoggedIn ? (
											<Route
												exact
												path={'/signin'}
												component={SignInScreen}
											/>
										) : (
											<Route
												path={'/'}
												component={AppRouter}
											/>
										)}
										{!props.isLoggedIn && (
											<Redirect to='/signin' />
										)}
									</Switch>
								</Suspense>
							</BrowserRouter>
						</MuiPickersUtilsProvider>
					</ThemeProvider>
			)}
		</>
	);
}

const mapStateToProps = state => ({
    isLoggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({setIsLoggedIn, }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
