import { SET_USER } from "../constants";

const initialState = {
    data: {},
}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                ...action,
            }
        default:
            return state;
    }
}