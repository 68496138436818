import React, { lazy, } from 'react';
import { Switch, Route, Redirect, } from 'react-router-dom';

const MainLayout = lazy(() => import('../layout/Main'));
const SpacesScreen = lazy(() => import('../screens/Spaces'));
const SpaceScreen = lazy(() => import('../screens/Space'));
const ActivityScreen = lazy(() => import('../screens/Activity'));

const AppRouter = () => {
    return (
        <MainLayout>
            <Switch>
                <Redirect
                    exact
                    from={'/'}
                    to={'/spaces'}
                />
                <Route
                    exact
                    path={'/spaces'}
                    component={SpacesScreen}
                />
                <Route
                    exact
                    path={'/users'}
                    component={SpacesScreen}
                />
                <Route
                    path={'/space/:id'}
                    component={SpaceScreen}
                />
                <Route
                    path={['/activity/:id/discussion/:discussion_id', '/activity/:id']}
                    component={ActivityScreen}
                />
                <Redirect to='/' />
            </Switch>
        </MainLayout>
    )
}

export default AppRouter;