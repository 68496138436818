import { LOGIN, LOGOUT } from "../constants";

const setIsLoggedIn = (payload) => {
    return {
        type: payload === true ? LOGIN : LOGOUT,
        isLoggedIn: payload
    }
}

export {
    setIsLoggedIn,
}