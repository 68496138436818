import { LOGIN, LOGOUT } from "../constants";

const initialState = {
    isLoggedIn: false,
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            //localStorage.setItem("is_logged_in", "true");
            return {
                ...state,
                isLoggedIn: true
            }
        case LOGOUT:
            //localStorage.removeItem("is_logged_in");
            return {
                ...state,
                isLoggedIn: false
            }
        default:
            return state;
    }
}