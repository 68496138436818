import store from '../redux/store';
import {setIsLoggedIn} from '../redux/actions/auth';

const request = async (action, options, isNotJSON) => {
    let paramOptions = options ? options : {};
    const isHeadersNull = paramOptions.headers === null ? true : false;

    if (!paramOptions.headers) {
        paramOptions.headers = {};
    }

    let defaultHeaders = {};

    if (isNotJSON !== true && isHeadersNull !== true) {
        defaultHeaders["Accept"] = "application/json";
        defaultHeaders["Content-Type"] = "application/json";
    }

    const { headers, ...others } = paramOptions;

    let opt = {
        headers: {
            ...defaultHeaders,
            ...headers,
        },
        ...others,
    }

    let response = await fetch(action, opt);
    let responseJSON

    if (isNotJSON !== true) {
        responseJSON = await response.json();
    }
    else {
        responseJSON = response;
    }

    if (responseJSON.is_force_signout === true) {
        store.dispatch(setIsLoggedIn(false));
        window.location.href="/signin";
    }
    else {
        return responseJSON;
    }
}

export {
    request,
}
