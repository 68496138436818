import palette from '../palette';

const obj = {
  root: {
    '& h2': {
        color: palette.text.primary,
        fontSize: 24
    }
  }
};

export default obj;